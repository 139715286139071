<template>
  <VBox>
    <div class="title-page">Summary</div>

    <VLoader v-if="_fetchingStatus === requestStatus.PENDING" />
    <div
      v-if="_fetchingStatus === requestStatus.SUCCESS"
      class="d-flex justify-content-center"
    >
      <SummaryBox
        v-for="summary in _formattedSummaryList"
        :key="summary.title"
        :title="summary.title"
        :price="summary.price"
        :info-text="summary.info"
      />
    </div>
  </VBox>
</template>

<script>
import VBox from '@/js/components/common/VBox';
import SummaryBox from '@/js/components/pages/payments/Requests/SummaryBox';
import { requestStatus } from '@/js/utils/constants';

export default {
  name: "RequestsSummary",
  components: { SummaryBox, VBox },
  data: () => ({
    requestStatus,
    summaryList: [
      {
        title: 'Billed amount',
        slug: 'billed_amount',
        price: 0,
        info: 'Sum of all your billed invoices, that are yet to be paid.'
      },
      {
        title: 'Paid amount',
        slug: 'paid_amount',
        price: 0,
        info: 'Sum of all your invoices that have been paid'
      },
    ],
  }),
  computed: {
    _fetchingStatus() {
      return this.$store.state.paymentRequests.fetchingStatusSummary;
    },
    _invoiceDetails() {
      return this.$store.state.paymentRequests.summary;
    },
    _formattedSummaryList() {
      if (Object.keys(this._invoiceDetails).length > 0) {
        return this.summaryList.map((summary) => ({
          ...summary,
          price: this._invoiceDetails[summary.slug].toFixed(2),
        }));
      }

      return this.summaryList;
    },
  },
  mounted() {
    this.getInvoiceSummary();
  },
  methods: {
    async getInvoiceSummary() {
      await this.$store.dispatch('paymentRequests/getInvoiceSummary');
    }
  },
};
</script>

<style lang="scss" scoped>

</style>