<template>
  <div>
    <div class="title-page">Payments</div>
    <div class="mb-5">
      <VTabs :tabs="tabs" :selected-tab="selectedTab" @click="handleTabClick" />
    </div>
    <component :is="selectedTab"></component>
  </div>
</template>

<script>
import VTabs from '@/js/components/common/VTabs';
import Methods from '@/js/components/pages/payments/Methods';
import Requests from '@/js/components/pages/payments/Requests';

export default {
  name: "PaymentMethods",
  components: {
    VTabs,
    Methods,
    Requests
  },
  data() {
    return {
      tabs: [
        {
          title: 'Payment Methods',
          component: 'methods'
        },
        {
          title: 'Payment Requests',
          component: 'requests'
        },
      ],
      selectedTab: 'methods'
    };
  },
  methods: {
    handleTabClick(tab) {
      this.selectedTab = tab.component;
    }
  }
};
</script>

<style scoped>

</style>