<template>
  <VBox class="payment-request">
    <VOverlay v-if="_updatingStatusFrequency === requestStatus.PENDING">
      <VLoader />
    </VOverlay>

    <div class="title-page">Request</div>

    <VLoader v-if="_fetchingStatus === requestStatus.PENDING" />
    <template v-if="_fetchingStatus === requestStatus.SUCCESS">
      <div class="payment-request__row mb-3">
        <VSelect
          :options="billingOptions"
          :value="form.billing"
          :error="$v.form.billing.$error"
          get-type="object"
          size="sm"
          @input="form.billing = $event.id"
        >
          <template #label>
            <div class="d-flex align-items-center mb-1">
              Billing frequency
              <VTooltip v-if="form.billing" class="payment-request__tooltip ml-1">
                {{ billingTooltipText[form.billing] }}
              </VTooltip>
            </div>
          </template>
        </VSelect>
      </div>

      <div v-if="form.billing === 'weekly'" class="payment-request__row mb-3">
        <VSelect
          label="Day"
          :options="daysOfWeek"
          :value="form.dayOfWeek"
          get-type="object"
          size="sm"
          @input="form.dayOfWeek = $event.id"
        />
      </div>

      <div v-if="form.billing === 'bimonthly'" class="payment-request__row mb-3">
        <VSelect
          label="Day of month 1"
          :options="daysOfMonth"
          :value="form.dayOfMonth1"
          get-type="object"
          size="sm"
          @input="form.dayOfMonth1 = $event.id"
        />
        <VSelect
          label="Day of month 2"
          :options="daysOfMonth"
          :value="form.dayOfMonth2"
          get-type="object"
          size="sm"
          @input="form.dayOfMonth2 = $event.id"
        />
      </div>

      <div class="d-flex justify-content-end">
        <VButton
          type="orange"
          class="btn-xl ml-3"
          @click="onSaveFrequency"
        >
          Save
        </VButton>
      </div>
    </template>
  </VBox>
</template>

<script>
import VBox from '@/js/components/common/VBox';
import VSelect from '@/js/components/common/VSelect';
import VTooltip from '@/js/components/common/VTooltip';
import { requestStatus, toasted, daysOfWeek, daysOfMonth } from '@/js/utils/constants';
import { required } from 'vuelidate/lib/validators';

export default {
  name: "PaymentRequest",
  components: { VTooltip, VSelect, VBox },
  data: () => ({
    requestStatus,
    daysOfWeek: daysOfWeek.map((day, key) => ({ id: key + 1, label: day })),
    daysOfMonth: daysOfMonth.map((day) => ({ id: day, label: day })),
    billingOptions: [
      { id: 'weekly', label: 'Weekly' },
      { id: 'bimonthly', label: 'Bimonthly' },
      { id: 'manual', label: 'Manual' },
    ],
    form: {
      billing: '',
      dayOfWeek: 1,
      dayOfMonth1: 1,
      dayOfMonth2: 1,
    },
    billingTooltipText: {
      weekly: 'Sets the day that the invoice would be generated per billing period',
      bimonthly: 'Sets two days in a month that the invoice would be generated per billing period',
      manual: 'Select preferred date range using a calendar and create an invoice by clicking \'Add invoice\' button.',
    },
  }),
  computed: {
    _invoiceDetails() {
      return this.$store.state.paymentRequests.details.billing;
    },
    _fetchingStatus() {
      return this.$store.state.paymentRequests.fetchingStatusSummary;
    },
    _updatingStatusFrequency() {
      return this.$store.state.paymentRequests.updatingStatusFrequency;
    },
  },
  validations: {
    form: {
      billing: {
        required,
      },
    },
  },
  methods: {
    async onSaveFrequency() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const { billing, dayOfWeek, dayOfMonth1, dayOfMonth2 } = this.form;
      const formData = {
        billing_frequency: billing
      };

      if (billing === 'weekly') {
        formData.day_of_week = dayOfWeek;
      }
      if (billing === 'bimonthly') {
        formData.day_of_month_one = dayOfMonth1;
        formData.day_of_month_two = dayOfMonth2;
      }

      try {
        await this.$store.dispatch('paymentRequests/updateFrequency', formData);
        this.$toasted.success(toasted.text.success);
      } catch (e) {
        this.$toasted.error(toasted.text.error);
      }
    },
  },
  watch: {
    _invoiceDetails(details) {
      const { billing_frequency, day_of_week, day_of_month_one, day_of_month_two } = details;

      this.form.billing = billing_frequency;

      if (billing_frequency === 'weekly') {
        this.form.dayOfWeek = day_of_week;
      }
      if (billing_frequency === 'bimonthly') {
        this.form.dayOfMonth1 = day_of_month_one;
        this.form.dayOfMonth2 = day_of_month_two;
      }
    },
    'form.billing'(value) {
      if (value === 'manual') this.$store.commit('paymentRequests/SET_BILLING_FREQUENCY', true);
      else this.$store.commit('paymentRequests/SET_BILLING_FREQUENCY', false);
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-request {
  position: relative;

  &__row {
    display: grid;
    grid-template-columns: repeat(2, 220px);
    grid-column-gap: 24px;
  }

  &__tooltip {
    position: relative;
  }
}
</style>