<template>
  <div class="summary-box p-2">
    <VTooltip class="summary-box__info">
      {{ infoText }}
    </VTooltip>
    <div class="text-md text-medium mb-1">{{ title }}</div>
    <div class="text-md text-medium">
      {{ $store.getters['auth/currencySign'] }} {{ price }}
    </div>
  </div>
</template>

<script>
import VTooltip from '@/js/components/common/VTooltip';

export default {
  name: "SummaryBox",
  components: { VTooltip },
  props: {
    title: String,
    price: String,
    infoText: String,
  },
};
</script>

<style lang="scss" scoped>
.summary-box {
  position: relative;
  width: 244px;
  height: 90px;
  margin: 0 12px;
  background: var(--white-main);
  border-radius: 10px;
  box-shadow: 0 2px 6px 2px rgba(0, 42, 81, 0.1);

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 9px;
    background-color: #CECBEA;
    border-radius: 0 0 10px 10px;
  }

  &:last-child {
    &:after {
      background-color: #948AE6;
    }
  }

  &__info {
    position: absolute;
    top: 3px;
    right: 3px;
  }
}
</style>