const includesEntries = (data) => {
  const current = {};

  Object.entries(data).forEach(param => {
    if (param[1] || Number.isInteger(param[1])) current[param[0]] = param[1];
  });

  return current;
};

export {
  includesEntries
}
