<template>
  <div
    class="v-tooltip"
    @mouseover="alertIsActive = true"
    @mouseleave="alertIsActive = false"
  >
    <IconInfoDefault v-if="icon === 'icon-info'" />
    <div
      v-if="alertIsActive"
      class="v-tooltip__alert"
      :style="{transform: `translateX(${alertXPosition}px`}"
      ref="alert"
    >
      <VAlert :type="type">
        <slot />
      </VAlert>
    </div>
  </div>
</template>

<script>
import IconInfoDefault from '@/js/components/icons/IconInfoDefault';
import VAlert from '@/js/components/common/VAlert';

export default {
  name: "VTooltip",
  components: { IconInfoDefault, VAlert },
  props: {
    type: {
      type: String,
      default: 'info',
    },
    icon: {
      type: String,
      default: 'icon-info'
    },
  },
  data: () => ({
    alertIsActive: false,
    alertXPosition: 0,
  }),
  watch: {
    async alertIsActive(next) {
      if (!next) {
        this.alertXPosition = 0;
        return;
      }
      await this.$nextTick();
      const { left, width } = this.$refs.alert.getBoundingClientRect();
      const diff = window.innerWidth - left - width;
      if (diff < 0) this.alertXPosition = diff - 20;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-tooltip {
  display: flex;
  cursor: pointer;

  &__alert {
    position: absolute;
    bottom: 100%;
    left: -16px;
    width: 305px;
    flex-shrink: 0;
    border-radius: 8px;
    z-index: 10;
    box-shadow: var(--main-box-shadow);
  }
}
</style>