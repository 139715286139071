<template>
  <div>
    <div class="d-flex justify-content-end mb-3">
      <VButton
        type="orange"
        class="btn-ml"
        @click="$router.push('/payments/create')"
      >
        Add payment method
      </VButton>
    </div>

    <VBox>
      <div class="methods__title text-semibold mb-3">Methods</div>

      <VLoader v-if="_fetchingStatus === requestStatus.PENDING" />
      <VTable
        v-else-if="_fetchingStatus === requestStatus.SUCCESS"
        :head-cols="_headCols"
        :body-rows="_methodsData"
        :optionsIsActive="true"
        @onRemoveRow="openPopupRemove"
        @onEditRow="editPaymentMethod"
      >
        <template #col-1="{ row }">
          <span class="color-blue-main"> {{ row.name }}</span>
        </template>
        <template #col-3="{ row }">
          <VCheckbox
            :checked="row.is_default"
            @change="openPopupChangeDefaultMethod(row)"
          />
        </template>

      </VTable>
    </VBox>
    <PopupConfirm
      v-if="popupConfirmIsVisible"
      size="sm"
      :loading="_deletingStatus === requestStatus.PENDING"
      @cancel="popupConfirmIsVisible = false"
      @confirm="removePaymentMethod"
    >
      <template #title>
        Delete confirmation
      </template>
      <template #body>
        <div class="text-center">
          Are you sure you want to delete details of <strong>{{ deletedPaymentMethod.name }}</strong> payment method
          permanently?
        </div>
      </template>
    </PopupConfirm>

    <PopupConfirm
      v-if="popupDefaultMethodIsVisible"
      :loading="_updatingStatus === requestStatus.PENDING"
      @cancel="popupDefaultMethodIsVisible = false"
      @confirm="onSetAsDefault"
    >
      <template #title>
        Changing default payment method
      </template>
      <template #body>
        <div class="mb-2">Your current payment method is: <strong>'{{ _defaultPaymentMethod.name }}'</strong></div>
        <div class="mb-2">Do you want to set <strong>'{{ defaultPaymentMethod.name }}'</strong> as default?</div>
        <div class="color-grey-main">Note: you can have only one active payment method</div>
      </template>
    </PopupConfirm>

  </div>
</template>

<script>
import VBox from '@/js/components/common/VBox';
import VTable from '@/js/components/common/VTable';
import { requestStatus, toasted } from '@/js/utils/constants';
import { defaultColumnsForMethods } from '@/js/utils/table/defaultColumns';
import PopupConfirm from "@/js/components/modal/PopupConfirm";
import VCheckbox from "@/js/components/common/VCheckbox";

export default {
  name: 'Methods',
  components: {
    VCheckbox,
    PopupConfirm,
    VTable,
    VBox
  },
  data: () => ({
    requestStatus,
    defaultColumns: defaultColumnsForMethods,
    popupDefaultMethodIsVisible: false,
    popupConfirmIsVisible: false,
    deletedPaymentMethod: {},
    defaultPaymentMethod: {},
  }),
  computed: {
    _headCols() {
      return this.defaultColumns;
    },
    _fetchingStatus() {
      return this.$store.state.paymentMethods.fetchingStatus;
    },
    _methodsData() {
      return this.$store.state.paymentMethods.list;
    },
    _updatingStatus() {
      return this.$store.state.paymentMethods.updatingStatus;
    },
    _deletingStatus() {
      return this.$store.state.paymentMethods.deletingStatus;
    },
    _defaultPaymentMethod() {
      return this.$store.state.paymentMethods.defaultPaymentMethod;
    },
  },
  mounted() {
    this.getMethods();
  },
  methods: {
    openPopupRemove(deletedItem) {
      this.deletedPaymentMethod = deletedItem;
      this.popupConfirmIsVisible = true;
    },
    async removePaymentMethod() {
      try {
        await this.$store.dispatch('paymentMethods/deletePaymentMethod', this.deletedPaymentMethod.id);
        this.$toasted.success(`Payment method ${this.deletedPaymentMethod.name} was successfully deleted`);
        this.popupConfirmIsVisible = false;
        this.deletedPaymentMethod = {};
      } catch (e) {
        this.$toasted.error(toasted.text.error);
      }
    },
    editPaymentMethod(paymentMethod) {
      this.$router.push(`/payments/edit/${paymentMethod.id}`);
    },
    openPopupChangeDefaultMethod(method) {
      this.popupDefaultMethodIsVisible = true;
      this.defaultPaymentMethod = method;
    },
    async onSetAsDefault() {
      try {
        await this.$store.dispatch('paymentMethods/setDefaultPaymentMethod', this.defaultPaymentMethod.id);
        this.$toasted.success(toasted.text.success);
        this.defaultPaymentMethod = {};
        this.popupDefaultMethodIsVisible = false;
      } catch (e) {
        this.$toasted.error(toasted.text.error);
      }
    },

    async getMethods() {
      await this.$store.dispatch('paymentMethods/getPaymentMethods');
    },
  }
};
</script>

<style lang="scss" scoped>
.methods {
  &__title {
    font-size: 18px;
  }
}
</style>