<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0001 9.71484C11.8486 9.71484 11.7032 9.77505 11.5961 9.88219C11.4889 9.98933 11.4287 10.1347 11.4287 10.2863V15.7149C11.4287 15.9191 11.5376 16.1077 11.7144 16.2098C11.8912 16.3118 12.1091 16.3118 12.2859 16.2098C12.4626 16.1077 12.5716 15.9191 12.5716 15.7149V10.2863C12.5716 10.1347 12.5114 9.98933 12.4042 9.88219C12.2971 9.77505 12.1517 9.71484 12.0001 9.71484Z"
      :fill="color" />
    <path
      d="M12.5934 7.74303C12.475 7.56395 12.2822 7.448 12.0687 7.42721C11.855 7.4063 11.6434 7.48308 11.4928 7.63589C11.3634 7.76459 11.2879 7.9378 11.2817 8.1202C11.2754 8.3026 11.3389 8.4804 11.4592 8.61764C11.5795 8.75488 11.7476 8.84111 11.9293 8.85884C12.1109 8.87657 12.2924 8.82427 12.4369 8.71292C12.5814 8.60144 12.6781 8.43906 12.7072 8.25897C12.7363 8.07874 12.6956 7.8943 12.5934 7.74302L12.5934 7.74303Z"
      :fill="color" />
    <path
      d="M12 4.00007C9.87826 4.00007 7.84354 4.84291 6.34313 6.34319C4.84288 7.84358 4 9.87855 4 12C4 14.1215 4.84284 16.1565 6.34313 17.6569C7.84351 19.1571 9.87849 20 12 20C14.1214 20 16.1564 19.1572 17.6568 17.6569C19.1571 16.1565 19.9999 14.1215 19.9999 12C19.9975 9.87898 19.1539 7.84557 17.6541 6.34581C16.1544 4.84604 14.1211 4.00242 11.9999 4L12 4.00007ZM12 18.8573C10.1813 18.8573 8.43718 18.1348 7.15129 16.8488C5.86531 15.5629 5.14286 13.8187 5.14286 12.0002C5.14286 10.1816 5.86531 8.43737 7.15129 7.15149C8.43727 5.86551 10.1814 5.14306 12 5.14306C13.8185 5.14306 15.5628 5.86551 16.8486 7.15149C18.1346 8.43747 18.8571 10.1816 18.8571 12.0002C18.855 13.8182 18.1319 15.561 16.8464 16.8466C15.5609 18.1321 13.8179 18.8552 12 18.8573Z"
      :fill="color" />
  </svg>
</template>

<script>
export default {
  name: "IconInfoDefault",
  props: {
    color: {
      type: String,
      default: '#BBBBBB'
    }
  }
};
</script>