<template>
  <VBox class="payment-history">
    <VOverlay v-if="_updatingStatus === requestStatus.PENDING">
      <VLoader />
    </VOverlay>

    <div class="title-page">Payment history</div>
    <div class="d-flex align-items-center justify-content-start mb-4">
      <v-button
        type="blue-filled"
        class="btn-xl mr-2"
        icon="columns"
        @click="$store.commit('modalTableColumns/TOGGLE_TABLE_COLUMNS', true)"
      >
        <span class="text-semibold">Columns</span>
      </v-button>

      <VSelect
        :options="statusOptions"
        :value="params.status"
        size="mini"
        get-type="object"
        class="payment-history__status mr-2"
        @input="getInvoicesByStatus"
      />

      <div
        v-if="$store.state.paymentRequests.billingFrequencyIsManual"
        class="d-flex align-items-center ml-auto"
      >
        <VDatePicker v-model="form.dateRange" />
        <VButton
          type="orange"
          class="btn-xl ml-3"
          @click="onAddInvoice"
        >
          Add invoice
        </VButton>
      </div>
    </div>

    <keep-alive>
      <VMask
        v-if="$store.state.modalTableColumns.toggleTableColumns"
        class="d-flex align-items-center justify-content-center"
      >
        <TableColumns
          title="Table columns"
          :default-columns="defaultColumns"
          :default-selected-columns="_headCols"
          :selected-columns-ids="_getSettings.selectedColumns"
          searchBy="selectedColumns"
          class="payment-history__table-columns"
          @save="setSelectedColumns"
        />
      </VMask>
    </keep-alive>

    <VLoader v-if="_fetchingStatus === requestStatus.PENDING" />
    <VTable
      v-else-if="_fetchingStatus === requestStatus.SUCCESS"
      :head-cols="_headCols"
      :body-rows="_tableData"
      actionsIsActive
      :actions-list="actionsList"
      @onAction="onActionRow"
      @onSortByCol="onSortByCol"
    >
      <template #col-0="{row}">
        <router-link :to="`/payments/invoices/${row.id}`">Invoice {{ row.id }}</router-link>
      </template>
      <template #col-6="{row}">
        {{ $store.getters['auth/currencySign'] }} {{ row.billed }}
      </template>
      <template #col-7="{row}">
        {{ $store.getters['auth/currencySign'] }} {{ row.paid }}
      </template>
      <template #col-8="{row}">
        {{ $store.getters['auth/currencySign'] }} {{ row.balance }}
      </template>
    </VTable>

    <PopupConfirm
      v-if="popupConfirmIsVisible"
      size="sm"
      :loading="_deletingStatus === requestStatus.PENDING"
      @cancel="onCancelInvoice"
      @confirm="onDeleteInvoice"
    >
      <template #title>Delete invoice</template>
      <template #body>
        <div class="text-center">Are you sure you want to delete this invoice?</div>
      </template>
    </PopupConfirm>
  </VBox>
</template>

<script>
import VBox from '@/js/components/common/VBox';
import VDatePicker from '@/js/components/common/VDatePicker';
import VMask from '@/js/components/common/VMask';
import VSelect from '@/js/components/common/VSelect';
import VTable from '@/js/components/common/VTable';
import PopupConfirm from '@/js/components/modal/PopupConfirm';
import TableColumns from '@/js/components/pages/offers/popup/TableColumns';
import InvoiceMixin from '@/js/mixins/invoiceMixin';
import { requestStatus, toasted } from '@/js/utils/constants';
import { includesEntries } from '@/js/utils/preps';
import { defaultMethodsForPayments } from '@/js/utils/table/defaultColumns';
import moment from 'moment';

export default {
  name: "PaymentHistory",
  components: { VDatePicker, PopupConfirm, VSelect, TableColumns, VMask, VTable, VBox },
  mixins: [InvoiceMixin],
  data: () => ({
    requestStatus,
    defaultColumns: defaultMethodsForPayments,
    statusOptions: [
      { id: 'all', label: 'Show all' },
      { id: 1, label: 'Paid' },
      { id: 2, label: 'Unpaid' },
      { id: 0, label: 'Deleted' },
    ],
    params: {
      status: 'all',
      page_size: 999,
      order_field: '',
      order_direction: '',
      from: '',
      to: '',
    },
    form: {
      dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    },
    popupConfirmIsVisible: false,
    deletedInvoiceId: null,
    actionsList: [
      {
        id: 0,
        label: 'Export as PDF',
        slug: 'export_as_pdf'
      },
      {
        id: 1,
        label: 'Delete invoice',
        slug: 'delete_invoice'
      }
    ],
  }),
  computed: {
    _getSettings() {
      return this.$store.getters['appSessionSettings/getPaymentsSettings'];
    },
    _headCols() {
      return this.defaultColumns
        .filter(column => this._getSettings.selectedColumns.includes(column.id))
        .sort((a, b) => a.id - b.id);
    },
    _fetchingStatus() {
      return this.$store.state.paymentRequests.fetchingStatus;
    },
    _tableData() {
      return this.$store.state.paymentRequests.invoices.map((invoice) => ({
        ...invoice,
        payment_method_name: invoice?.payment_method?.name,
      }));
    },
    _deletingStatus() {
      return this.$store.state.paymentRequests.deletingStatus;
    },
    _updatingStatus() {
      return this.$store.state.paymentRequests.updatingStatus;
    },
  },
  mounted() {
    this.getInvoices();
  },
  methods: {
    async getInvoices() {
      const { status, ...params } = includesEntries(this.params);

      if (status !== 'all') params.status = status;

      await this.$store.dispatch('paymentRequests/getInvoices', params);
    },
    getInvoicesByStatus(status) {
      this.params.status = status.id;
      this.getInvoices();
    },
    onActionRow(actionSlug, row) {
      if (actionSlug === 'export_as_pdf') {
        this.exportInvoice(row.id);
      }

      if (actionSlug === 'delete_invoice') {
        this.setDeletedInvoiceId(row.id);
      }
    },
    setDeletedInvoiceId(invoiceId) {
      this.deletedInvoiceId = invoiceId;
      this.popupConfirmIsVisible = true;
    },
    async onDeleteInvoice() {
      try {
        await this.$store.dispatch('paymentRequests/deleteInvoice', this.deletedInvoiceId);
        this.$toasted.success('This invoice was successfully deleted');
        this.popupConfirmIsVisible = false;
      } catch (e) {
        this.$toasted.error(toasted.text.error);
      }
    },
    onCancelInvoice() {
      this.popupConfirmIsVisible = false;
      this.deletedInvoiceId = null;
    },
    async onAddInvoice() {
      const formData = {
        start_date: this.form.dateRange[0],
        end_date: this.form.dateRange[1],
      };

      try {
        await this.$store.dispatch('paymentRequests/addInvoice', formData);
        this.$toasted.success(toasted.text.success);
      } catch (e) {
        const { data } = e.response;
        this.$toasted.error(data.message);
      }
    },
    setSelectedColumns(selectedColumns) {
      this.$store.commit('appSessionSettings/SET_SELECTED_COLUMNS', {
        pageKey: 'payments',
        selectedColumns,
      });
      this.$store.dispatch('appSessionSettings/updateSettings');
    },
    onSortByCol(order) {
      this._headCols.forEach(item => item.orderBy = 'def');
      const current = this._headCols.find(item => item.id === order.id);
      this.params.order_field = order.sort;

      switch (order.orderBy) {
        case 'def':
          this.params.order_direction = 'asc';
          this.$set(current, 'orderBy', 'asc');
          break;
        case 'asc':
          this.params.order_direction = 'asc';
          this.$set(current, 'orderBy', 'asc');
          break;
        case 'desc':
          this.params.order_direction = 'desc';
          this.$set(current, 'orderBy', 'desc');
          break;
        default:
          break;
      }

      this.getInvoices();
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-history {
  position: relative;

  &__table-columns {
    width: 100%;
    max-width: 480px;
  }

  &__status {
    width: 100%;
    max-width: 215px;
  }
}
</style>