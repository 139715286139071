import { toasted } from '@/js/utils/constants';

export default {
  methods: {
    async exportInvoice(invoiceId) {
      try {
        const data = await this.$store.dispatch('paymentRequests/exportInvoice', invoiceId);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invoice_${invoiceId}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        this.$toasted.error(toasted.text.error);
      }
    },
  }
}