<template>
  <div>
    <RequestsSummary class="mb-2" />
    <PaymentRequest class="mb-4" />
    <PaymentHistory />
  </div>
</template>

<script>
import PaymentHistory from '@/js/components/pages/payments/Requests/PaymentHistory';
import PaymentRequest from '@/js/components/pages/payments/Requests/PaymentRequest';
import RequestsSummary from '@/js/components/pages/payments/Requests/RequestsSummary';

export default {
  name: 'Request',
  components: { PaymentHistory, PaymentRequest, RequestsSummary }
};
</script>