<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_2266_48892" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_2266_48892)">
      <path
        d="M2.50017 22C2.36959 22 2.24179 21.9488 2.14651 21.8536C2.01217 21.7193 1.96557 21.5205 2.02612 21.3404L3.43515 17.1596C2.49569 15.6029 2.00015 13.8244 2.00015 12C2.00015 6.48607 6.48622 2 12.0002 2C17.5141 2 22.0002 6.48607 22.0002 12C22.0002 17.5139 17.5141 22 12.0002 22C10.1759 22 8.39729 21.5045 6.84087 20.5647L2.65979 21.9738C2.60762 21.9913 2.55377 22 2.50019 22L2.50017 22ZM6.90409 19.5157C6.99812 19.5157 7.09146 19.5423 7.17265 19.5941C8.61672 20.5138 10.2859 20.9999 12.0001 20.9999C16.9626 20.9999 21.0001 16.9624 21.0001 11.9999C21.0001 7.03743 16.9626 2.99993 12.0001 2.99993C7.03765 2.99993 3.00015 7.03743 3.00015 11.9999C3.00015 13.7141 3.48633 15.3836 4.40611 16.8271C4.48731 16.9549 4.50656 17.1122 4.45829 17.2556L3.29589 20.7042L6.74454 19.5419C6.79657 19.5244 6.85028 19.5157 6.90414 19.5157L6.90409 19.5157Z"
        fill="#1E70BB" />
      <path
        d="M12.5 16.9814C12.5 17.2577 12.2761 17.4814 12 17.4814C11.7239 17.4814 11.5 17.2577 11.5 16.9814C11.5 16.7054 11.7239 16.4814 12 16.4814C12.2761 16.4814 12.5 16.7054 12.5 16.9814Z"
        fill="#1E70BB" />
      <path
        d="M12 15.3754C11.7238 15.3754 11.5 15.1516 11.5 14.8754V7.31934C11.5 7.04311 11.7238 6.81934 12 6.81934C12.2762 6.81934 12.5 7.04311 12.5 7.31934V14.8754C12.5 15.1516 12.2762 15.3754 12 15.3754Z"
        fill="#1E70BB" />
    </g>
  </svg>

</template>

<script>
export default {
  name: "IconInfo",
};
</script>