<template>
  <div
    class="v-alert"
    :class="[
      type ? `v-alert--${type}` : '',
      size ? `v-alert--${size}` : '',
      {'v-alert--full-width': fullWidth},
    ]"
  >
    <icon-info class="v-alert__icon mr-1" />
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
import IconInfo from '@/js/components/icons/IconInfo';
export default {
  name: "VAlert",
  components: { IconInfo },
  props: {
    type: {
      type: String,
      default: 'info'
    },
    size: {
      type: String,
      default: 'md'
    },
    fullWidth: Boolean,
  }
};
</script>

<style lang="scss" scoped>
.v-alert {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 16px;
  border-radius: 8px;

  &--info {
    background-color: var(--blue-light);
  }

  &--sm {
    max-width: 305px;
  }

  &--md {
    max-width: 464px;
  }

  &--full-width {
    max-width: 100%;
  }

  &__icon {
    flex-shrink: 0;
  }
}
</style>